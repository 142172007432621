<app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>
<div class="list-container" [hidden]="!dataPerMapDataType">

  <ng-container *ngFor="let mapDataType of dataMapDataTypes">
    <h5>{{mapDataType}}</h5>
    <table
            [dataSource]="dataPerMapDataType.get(mapDataType)"
            aria-label="List of available scenarios"
            mat-table
            multiTemplateDataRows
    >
      <colgroup>
        <col span="1" style="width:10%" />
        <col span="1" style="width:50%" />
        <col span="1" style="width:20%" />
        <col span="1" style="width:30%" />
      </colgroup>

      <ng-container matColumnDef="mapDataType">
        <th *matHeaderCellDef mat-header-cell scope="col">MapDataType</th>
        <td *matCellDef="let element" mat-cell>
          {{element.mapDataType}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell scope="col">Catalog Name</th>
        <td *matCellDef="let element" mat-cell>
          {{element.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="latestVersion">
        <th *matHeaderCellDef mat-header-cell scope="col">Latest Version</th>
        <td *matCellDef="let element" mat-cell>
          {{element.version}}
        </td>
      </ng-container>

      <ng-container matColumnDef="publishedAt">
        <th *matHeaderCellDef mat-header-cell scope="col">Published At</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.publishedAt|date:'yyyy-MM-dd HH:mm:ss z' }}
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr
              *matRowDef="let element; columns: displayedColumns;"
              class="element-row"
              mat-row
      ></tr>
    </table>
    <br/>
    <br/>
  </ng-container>
</div>
