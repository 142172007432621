import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import { FormBuilder, NgControl, Validators } from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import { AbstractInputFormControlComponent } from '@shared/component/form-control/common/abstract-input-form-control/abstract-input-form-control.component';
import {
  CatalogApiEntity,
  CatalogVersionApiEntity
} from '@shared/model/productserver';
import { CatalogService } from '@shared/service/catalog.service';
import { ProductServerService } from '@shared/service/product-server.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-catalog-version-form-control',
  templateUrl: './catalog-version-form-control.component.html',
  styleUrls: ['./catalog-version-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CatalogVersionFormControlComponent
    }
  ]
})
export class CatalogVersionFormControlComponent
  extends AbstractInputFormControlComponent<number>
  implements OnDestroy
{
  public isLoading: boolean = false;
  public autocomplete$ = new Array<CatalogVersionApiEntity>();

  parts = this._formBuilder.group({
    version: this._formBuilder.control<number>(null, {
      nonNullable: true,
      validators: [Validators.required]
    })
  });

  readonly controlType: string = 'catalog-version-form';

  @HostBinding() id: string = `${
    this.controlType
  }-${CatalogVersionFormControlComponent.nextId++}`;

  subscription: Subscription = new Subscription();

  constructor(
    private productServerService: ProductServerService,
    private catalogService: CatalogService,
    _formBuilder: FormBuilder,
    _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_formBuilder, _focusMonitor, _elementRef, _formField, ngControl);
  }

  get empty(): boolean {
    const {
      value: { version }
    } = this.parts;
    return !isFinite(version);
  }

  @Input()
  get value(): number | null {
    if (this.parts.valid) {
      const {
        value: { version }
      } = this.parts;
      return version;
    }
    return null;
  }

  set value(value: number) {
    this.parts.setValue({ version: value ?? null });
    this.stateChanges.next();
  }

  private _catalog: CatalogApiEntity = null;

  @Input() set catalog(value) {
    this.catalogService.getCatalogByName(value).subscribe((catalog) => {
      this._catalog = catalog;
      this.getCatalogVersions(catalog.name);
    });
  }

  get catalogName(): string {
    return this._catalog?.name;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  getCatalogVersions(catalogName: string): void {
    this.isLoading = true;
    this.subscription.add(
      this.productServerService.getCatalogVersions(catalogName).subscribe(
        (catalogVersions) => {
          this.autocomplete$ = catalogVersions;
          this.isLoading = false;
        },
        (_error) => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      )
    );
  }

  _onSelectionChange(value: any) {
    this.value = value;
  }
}
