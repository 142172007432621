<form [formGroup]="form">
  <div class="kf-form-row kf-layout-gap-2">
    <div class="kf-form-col-50">
      <h3>Main Catalog</h3>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Map Data Type</mat-label>
        <mat-icon matSuffix>grid_on</mat-icon>
        <mat-select (selectionChange)="onMapDataSelectionChange()" formControlName="mapDataType" panelClass="kf-catalog-select">
          <mat-option *ngFor="let mapDataType of mapDataTypeOptions"
                      [value]="mapDataType">
            {{mapDataType}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Name</mat-label>
        <mat-icon *ngIf="!isLoadingCatalogs" matSuffix>map</mat-icon>
        <mat-progress-spinner *ngIf="isLoadingCatalogs" matSuffix diameter=20 mode="indeterminate"
                              color="accent"></mat-progress-spinner>
        <mat-select [attr.disabled]="isLoadingCatalogs"  formControlName="name" panelClass="kf-catalog-select">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let catalogName of catalogNames"
                      [value]="catalogName.name">
            {{catalogName.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Version</mat-label>
        <mat-icon *ngIf="!isLoadingVersions.catalog" matSuffix>compare</mat-icon>
        <mat-progress-spinner *ngIf="isLoadingVersions.catalog" color="accent" diameter="20" matSuffix
                              mode="indeterminate"></mat-progress-spinner>
        <input [matAutocomplete]="autoVersion" [readonly]="isLoadingVersions.catalog" formControlName="version" matInput placeholder="eg. 123"
               type="number" />
        <mat-autocomplete #autoVersion="matAutocomplete">
          <mat-option *ngFor="let option of autocomplete$.catalog || []" [value]="option.version">
            {{option.version|mapCatalogVersion:form.get('name').value}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="kf-form-col-50">
      <h3>Comparison Catalog</h3>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Map Data Type</mat-label>
        <mat-icon matSuffix>grid_on</mat-icon>
        <mat-select  (selectionChange)="onComparisonMapDataSelectionChange()" formControlName="comparisonMapDataType" panelClass="kf-catalog-select">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let mapDataType of mapDataTypeOptions"
                      [value]="mapDataType">
            {{mapDataType}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Name</mat-label>
        <mat-icon *ngIf="!isLoadingCatalogs" matSuffix>map</mat-icon>
        <mat-progress-spinner *ngIf="isLoadingCatalogs" color="accent" diameter="20" matSuffix
                              mode="indeterminate"></mat-progress-spinner>
        <mat-select [attr.disabled]="isLoadingCatalogs" formControlName="deltaName" panelClass="kf-catalog-select">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let catalogName of comparisonCatalogNames"
                      [value]="catalogName.name">
            {{catalogName.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Version</mat-label>
        <mat-icon *ngIf="!isLoadingVersions.deltaCatalog" matSuffix>compare</mat-icon>
        <mat-progress-spinner *ngIf="isLoadingVersions.deltaCatalog" color="accent" diameter="20" matSuffix
                              mode="indeterminate"></mat-progress-spinner>
        <input [matAutocomplete]="compareAutoVersion" [readonly]="isLoadingVersions.deltaCatalog" formControlName="deltaVersion" matInput placeholder="eg. 123"
               type="number" />
        <mat-autocomplete #compareAutoVersion="matAutocomplete">
          <mat-option *ngFor="let option of autocomplete$.deltaCatalog || []" [value]="option.version">
            {{option.version|mapCatalogVersion:form.get('deltaName').value}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</form>

