<form [formGroup]="form">
  <div class="kf-form-row kf-layout-gap-2">
    <div class="kf-form-col-50">
      <h3 class="draggable-header" style="font-size: 16px;">Main Catalog</h3>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Map Data Type</mat-label>
        <mat-select (selectionChange)="onMapDataSelectionChange()" formControlName="mapDataType" panelClass="kf-catalog-select">
          <mat-option *ngFor="let mapDataType of mapDataTypeOptions"
                      [value]="mapDataType">
            {{mapDataType}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Name</mat-label>
        <mat-progress-spinner *ngIf="isLoadingCatalogs" color="accent" diameter="20" matSuffix
                              mode="indeterminate"></mat-progress-spinner>
        <mat-select (selectionChange)="onCatalogNameSelectionChange($event)" [attr.disabled]="isLoadingCatalogs" formControlName="name"
                    panelClass="kf-catalog-select">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let catalogName of catalogNames"
                      [value]="catalogName.name">
            {{catalogName.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Version</mat-label>
        <mat-icon *ngIf="!isLoadingVersions.catalog" matSuffix>compare</mat-icon>
        <mat-progress-spinner *ngIf="isLoadingVersions.catalog" color="accent" diameter="20" matSuffix
                              mode="indeterminate"></mat-progress-spinner>
        <input (input)="onCatalogVersionSelectionChange()" [matAutocomplete]="autoVersion" [readonly]="isLoadingVersions.catalog"
               formControlName="version" matInput placeholder="eg. 123"
               type="number" />
        <mat-autocomplete #autoVersion="matAutocomplete" (optionSelected)="onCatalogVersionSelectionChangeAuto($event)">
          <mat-option *ngFor="let option of autocomplete$.catalog || []" [value]="option.version">
            {{option.version|mapCatalogVersion:form.get('name').value}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="kf-form-col-50">
      <h3 class="draggable-header" style="font-size: 16px;">Comparison Catalog</h3>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Map Data Type</mat-label>
        <mat-select (selectionChange)="onComparisonMapDataSelectionChange($event)" formControlName="comparisonMapDataType" panelClass="kf-catalog-select">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let mapDataType of mapDataTypeOptions"
                      [value]="mapDataType">
            {{mapDataType}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Name</mat-label>
        <mat-progress-spinner *ngIf="isLoadingCatalogs" color="accent" diameter="20" matSuffix
                              mode="indeterminate"></mat-progress-spinner>
        <mat-select (selectionChange)="onComparisonCatalogNameSelectionChange($event)" [attr.disabled]="isLoadingCatalogs" formControlName="deltaName"
                    panelClass="kf-catalog-select">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let catalogName of comparisonCatalogNames"
                      [value]="catalogName.name">
            {{catalogName.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Version</mat-label>
        <mat-icon *ngIf="!isLoadingVersions.deltaCatalog" matSuffix>compare</mat-icon>
        <mat-progress-spinner *ngIf="isLoadingVersions.deltaCatalog" color="accent" diameter="20" matSuffix
                              mode="indeterminate"></mat-progress-spinner>
        <input (input)="onComparisonCatalogVersionSelectionChange()" [matAutocomplete]="compareAutoVersion" [readonly]="isLoadingVersions.deltaCatalog"
               formControlName="deltaVersion" matInput placeholder="eg. 123"
               type="number" />
        <mat-autocomplete #compareAutoVersion="matAutocomplete" (optionSelected)="onDeltaCatalogVersionSelectionChangeAuto($event)">
          <mat-option *ngFor="let option of autocomplete$.deltaCatalog || []" [value]="option.version">
            {{option.version|mapCatalogVersion:form.get('deltaName').value}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</form>

