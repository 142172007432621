import { ResponseMap } from '../model/response';

export interface ResultDataApiEntity {
  testCaseId: string;
  testCaseVersion: number;
  catalogName: string;
  catalogVersion: number;
  comparisonCatalogName: string;
  comparisonCatalogVersion: number;
  layer: string;
  dataVersion: number;
  tileId: number;
  resultTypeVersion: number;
  timestamp: string;
  resultOutcome: ResultOutcome;
  resultType: ResultType;
  resultDetails: any;
  executionDetails: string;
  executionTime: number;
}

export enum ResultOutcome {
  PASS = 'PASS',
  FAIL = 'FAIL',
  NOT_EXECUTED = 'NOT_EXECUTED',
  FAILURE = 'FAILURE'
}

export enum ResultDeltaOutcome {
  EQUAL = 'EQUAL',
  INC = 'INC',
  DEC = 'DEC',
  NOT_EXECUTED = 'NOT_EXECUTED',
  FAILURE = 'FAILURE'
}

export enum ResultType {
  STATISTIC = 'STATISTIC',
  STATISTIC_DETAILED = 'STATISTIC_DETAILED',
  COMBINED_DETAILED_STATISTIC = 'COMBINED_DETAILED_STATISTIC',
  FORMAT_CONTENT = 'FORMAT_CONTENT',
  FORMAT_CONTENT_DETAILED = 'FORMAT_CONTENT_DETAILED',
  VALIDATIONSUITE = 'VALIDATIONSUITE',
  GROUND_TRUTH_SPOTLIGHT = 'GROUND_TRUTH_SPOTLIGHT',
  MAP_APPROVAL = 'MAP_APPROVAL'
}

export enum FailVisualization {
  FAIL_RATE = 'FAIL_RATE',
  FAIL_COUNT = 'FAIL_COUNT'
}

export enum FunctionalRoadClass {
  FRC_UNDEFINED = 'FRC_UNDEFINED',
  FRC_0 = 'FRC_0',
  FRC_1 = 'FRC_1',
  FRC_2 = 'FRC_2',
  FRC_3 = 'FRC_3',
  FRC_4 = 'FRC_4',
  FRC_5 = 'FRC_5',
  FRC_6 = 'FRC_6',
  FRC_7 = 'FRC_7',
  ALL = 'ALL'
}

export enum AttributionStatus {
  NOT_FIELD_SURVEYED = 'NOT_FIELD_SURVEYED',
  PARTLY_ATTRIBUTED = 'PARTLY_ATTRIBUTED',
  FULLY_ATTRIBUTED = 'FULLY_ATTRIBUTED',
  ALL = 'ALL'
}

export enum ControlledAccess {
  CONTROLLED_ACCESS = 'CONTROLLED_ACCESS',
  NOT_CONTROLLED_ACCESS = 'NOT_CONTROLLED_ACCESS',
  ALL = 'ALL'
}

export interface ResultFilter {
  testCaseIds?: Array<string> | any;
  tileIds?: Array<number> | any;
  areaIds?: Array<number>;
  resultOutcomes?: Array<ResultOutcome>;
  catalogVersion?: number;
  comparisonCatalogName?: string;
  comparisonCatalogVersion?: number;
}

export interface TestCaseFilter extends ResultFilter {
  catalogName: string;
}

export interface ScenarioRunFilter extends ResultFilter {
  runId: string;
}

export interface Bounds {
  ne: number[];
  sw: number[];
}

export interface LatLng {
  latitude: number;
  longitude: number;
}

export interface TilesApiEntity {
  bounds: Bounds;
}

export class TileMapApiEntity extends ResponseMap<TilesApiEntity> {
  constructor(data: Record<string, TilesApiEntity>) {
    super(data);
  }
}

export interface MapViewerAreasApiEntity {
  areaId: number;
  name: string;
  description?: string;
  tileIds: string[];
  bounds: Bounds;
  updated: string;
}

export interface TestCaseApiEntity {
  testCaseId: string;
  testCaseName: string;
  resultType: ResultType;
  mainLayer: string;
}

export interface TileEntity {
  resultOutcome: ResultOutcome;
  failRate: number;
  failCount: number;
  value: number;
  unit: string;
  totalCount?: number;
}

export interface DataVersionTileEntity {
  dataVersion: number;
}

export interface DataVersionResult {
  tileId: number;
  dataVersion: number;
}

export interface DeltaTileEntity {
  deltaOutcome: ResultDeltaOutcome;
  resultOutcome1: ResultOutcome;
  resultOutcome2: ResultOutcome;
  failRateDelta: number;
  valueDelta: number;
  unit: string;
}

export interface CatalogInfoVisualization {
  mapDataType: string;
  name: string;
  mappedTimestamp: boolean;
}
