import { Pipe, PipeTransform } from '@angular/core';
import { CatalogService } from '@shared/service/catalog.service';
import { CatalogApiEntityObject } from '@shared/model/productserver';

@Pipe({
  name: 'mapCatalogVersion',
  pure: false
})
export class MapCatalogVersionPipe implements PipeTransform {
  constructor(private catalogService: CatalogService) {}

  transform(catalogVersion: number, catalogName: string): string {
    if (!catalogVersion || !catalogName) {
      return '';
    }

    let value = `${catalogVersion}`;
    this.catalogService.getCatalogByName(catalogName).subscribe((result) => {
      if(result) {
        const catalog: CatalogApiEntityObject = CatalogApiEntityObject.fromApi(result);
        value = `${catalog.getMappedVersion(catalogVersion)}`;
      }
    });
    return value;
  }
}
