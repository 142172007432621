import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CatalogApiEntity, CatalogApiEntityObject } from '@shared/model/productserver';
import { ProductServerService } from '@shared/service/product-server.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.scss']
})
export class CatalogListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'mapDataType',
    'name',
    'latestVersion',
    'publishedAt'
  ];

  dataMapDataTypes: string[] = [];
  dataPerMapDataType: Map<string, MatTableDataSource<CatalogApiEntityObject>> =
    new Map<string, MatTableDataSource<CatalogApiEntityObject>>();

  isLoadingResults = false;

  private subscription: Subscription = new Subscription();

  constructor(public productServerService: ProductServerService) {}

  ngOnInit(): void {
    this.loadCatalogList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadCatalogList(): void {
    this.isLoadingResults = true;

    this.dataMapDataTypes = [];
    this.subscription.add(
      this.productServerService.getCatalogs().subscribe(
        // scenarioArray is the response.data which is received from the rest call
        (catalogApiEntities: CatalogApiEntity[]) => {
          for (const [mapDataType, catalogApiEntries] of this.productServerService
            .groupCatalogsByMapDataType(
              this.productServerService.sortCatalogsByMapDataType(
                catalogApiEntities
              )
            )
            .entries()) {
            this.dataMapDataTypes.push(mapDataType);
            this.dataPerMapDataType.set(
              mapDataType,
              new MatTableDataSource(
                this.productServerService.sortCatalogsByPublishedAt(catalogApiEntries).map(catalogApiEntries => CatalogApiEntityObject.fromApi(catalogApiEntries))
              )
            );
          }
          this.isLoadingResults = false;
        },
        (error) => {
          this.isLoadingResults = false;
          console.error(error);
        }
      )
    );
  }
}
