<ng-container>
    <label class="kf-label">Select Catalog</label>
    <div class="kf-form-catalog-select">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <span matSuffix [class.spinner]="isLoadingResults"></span>
            <input matInput *ngIf="isLoadingResults" />
            <mat-select *ngIf="!isLoadingResults"
                        id="selectedCatalogName"
                        [value]="mainCatalog?.name"
                        (valueChange)="selectMainCatalog($event)"
                        panelClass="kf-catalog-select"
                        required
            >
                <mat-option [value]="null">None</mat-option>
                <mat-optgroup *ngFor="let catalogGroup of catalogs | keyvalue:asIsOrder" [label]="catalogGroup.key">
                    <mat-option
                            *ngFor="let catalog of catalogGroup.value"
                            [value]="catalog.name"
                    >{{catalog.name|catalogName}}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Version</mat-label>
            <input
                    id="selectedCatalogVersion"
                    #catalogVersionInput
                    [formControl]="mainCatalogVersionsFormControl"
                    [matAutocomplete]="autocompleteCatalogVersion"
                    matInput
                    placeholder="eg. 123"
                    type="text"
                    required
            >
            <mat-autocomplete
                #autocompleteCatalogVersion="matAutocomplete"
                (optionSelected)="selectMainCatalogVersion($event.option.value)"
                panelWidth="200px"
            >
                <mat-option
                        *ngFor="let catalogVersion of autoCompleteCatalogVersionList | async"
                        [value]="catalogVersion"
                >{{catalogVersion|mapCatalogVersion:mainCatalog?.name}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</ng-container>
